.banner-container {
	background-image: url("https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2Fbanner-bg.png?alt=media&token=d28c6554-53fe-4909-b632-e0171632cef5");
	background-size: cover;
	background-repeat: no-repeat;
}

.upper-banner-container {
	background-image: url("https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2Fupper-banner.png?alt=media&token=8c55a7bc-317c-44f5-8f4a-8afbdf27992a"),
		url("https://firebasestorage.googleapis.com/v0/b/beyondlottotv.appspot.com/o/images%2Fupper-banner-bg-new.png?alt=media&token=be62ef60-5ab6-49db-8bfa-d8c736734d88");
	background-repeat: no-repeat, no-repeat;
	background-position: right top, right top;
}

@media screen and (max-width: 767px) {
	.banner-container {
	}

	.upper-banner-container {
		background-image: none;
	}
}
